<template>
  <div class="registration-detail">
    <v-container>
      <div class="main-container">
        <v-card
          rounded="lg"
          class="pa-9"
          :class="{'py-9 px-4': $vuetify.breakpoint.smAndDown}"
        >
          <h2>{{ 'registration.detail.title' | text }}</h2>
          <div class="registration-detail__status mt-4">
            <v-row 
              no-gutters
              v-for="item in getStatus"
              :key="item.title"
            >
              <v-col
                cols="3"
                sm="3"
                md="3"
                lg="2"
              >
                <p>{{ item.title | text }}</p>
              </v-col>
              <v-col
                cols="9"
                sm="9"
                md="9"
                lg="10"
              >
                <p>{{ `: ${item.value}` }}</p>
              </v-col>
            </v-row>
          </div>
          <v-divider></v-divider>
          <v-alert
            v-if="interviewDetail.length > 0 && ifWaitAlert"
            class="mt-4"
            color="blue-grey"
            dark
            dense
            icon="mdi-school"
            prominent
          >
            <p v-html="interviewDetail"></p>
          </v-alert>
          <v-alert
            v-else-if="ifWaitAlert"
            text
            dense
            color="teal"
            icon="mdi-clock-fast"
            border="left"
            class="title-14 mt-4"
          >
            {{alertInfoMsg}}
          </v-alert>
          <v-alert
            v-else-if="isFormAccepted"
            outlined
            type="success"
            text
            class="title-14 mt-4"
          >
            {{alertInfoMsg}}
          </v-alert>
          <v-alert
            v-else-if="isRegistrationRejected"
            outlined
            type="error"
            text
            class="title-14 mt-4"
          >
            <p v-html="rejectedReason"></p>
          </v-alert>
          <v-alert
            dismissible
            type="error"
            class="mt-4"
            v-model="isShowAlert"
          >{{ alertMsg }}</v-alert>
          <div class="mt-4" v-if="isFormAccepted">
            <validation-observer ref="form">
              <v-form id="form-student-agree-data" @submit.prevent="submitStudentAgreement">
                <v-row no-gutters class="general-form">
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                    lg="6"
                    class="general-form__col"
                  >
                    <registration-input 
                      :key="getStudentAgreementData.title" 
                      :form="getStudentAgreementData"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                    lg="6"
                    class="general-form__col"
                  >
                    <v-btn 
                      color="primary"
                      :min-width="160"
                      type="submit"
                      form="form-student-agree-data"
                      :disabled="(getStudentAgreementData.value && getStudentAgreementData.value.length === 0) || getStudentAgreementData.isDisabled "
                      class="mt-7"
                      large
                    >
                      KIRIM
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </validation-observer>
          </div>
          <div class="mt-4">
            <p class="title-14 mb-2">Gelombang Pendaftar</p>
            <v-select
              v-model="universityIntake"
              :items="universityIntakes"
              :disabled="isHideButton"
              dense
              outlined
            ></v-select>
          </div>
          <p class="title-14 mb-2">Tahap Pendaftaran</p>
          <stepper :prodi="programStudy" :period="period"/>
          <div v-if="!isHideButton" class="d-flex justify-end">
            <v-btn 
              color="primary"
              :min-width="120"
              outlined
              large
              class="mr-2"
              @click="handleSave"
            >
              SIMPAN
            </v-btn>
            <v-btn 
              color="primary"
              :min-width="120"
              large
              :disabled="isDisabled"
              @click="submitForm"
            >
              KIRIM
            </v-btn>
          </div>
        </v-card>
      </div>
    </v-container>
    <dialog-info ref="dialogInfoSuccess" :info="infoSuccess" :action="handleBack"/>
    <dialog-confirm ref="dialogConfirmSubmitForm" :info="info" :action="handleOnSubmit"/>
    <dialog-confirm ref="dialogConfirmSubmitStudentAgreement" :info="info" :action="handleSubmitStudentAgreement"/>
  </div>
</template>

<script>
// Libs 
import { get } from 'dot-prop'
import { ValidationObserver, extend } from 'vee-validate'

// Components
import stepper from '@/components/stepper'
import DialogConfirm from '@/components/common/DialogConfirm'
import DialogInfo from '@/components/common/DialogInfo'
import RegistrationInput from '@/components/common/registrationInput'

// Constants
import { text } from '@/apps/web/constants/text'
import { REGISTRATION_STATUS } from '@/constants/pages'
import { 
  API_FORM_SUBMIT,
  API_STUDENT_FORM_SUBMIT
} from '@/constants/apis'
import { FORM_COMPLETE, INIT, ACCEPTED, VALIDATE_COMPLETE, PASSED, FORM_PENDING, VALIDATE_PENDING, REJECTED, STUDENT_AGREE } from '@/constants/status'

// Utils
import loadscreen from '@/utils/common/loadScreen'
import request from '@/utils/request'
import store from '@/store'
import moment from 'moment'

  export default {
    name: 'RegistrationDetail',
    components: {
      ValidationObserver,
      RegistrationInput,
      stepper,
      DialogConfirm,
      DialogInfo
    },
    data () {
      return {
        universityIntake: '',
        alertMsg: '',
        isShowAlert: false,
        programStudy: '',
        prodiTitle: '',
        period: '',
        info: {
          desc: 'Anda yakin data yang akan anda kirim sudah benar?'
        },
        infoSuccess: {
          title: 'dialog.success',
          desc: 'dialog.success.submit.form',
          btn: 'dialog.success.btn'
        },
        alertInfoMsg: '',
      }
    },
    watch: {
      universityIntake() {
        this.$store.dispatch('setSelectedAcademicYear', this.universityIntake)
      }
    },
    computed: {
      getStatus() {
        return [{
          title: 'registration.detail.status.program',
          value: this.prodiTitle
        }, {
          title: 'registration.detail.status.periode',
          value: this.period
        }]
      },
      universityIntakes() {
        const academicYear = get(this.$store.state, 'academicYear', [])
        const list = []
        academicYear.map(itm => {
          list.push(itm.title)
        })
        return list
      },
      getUniversityIntake () {
        const academicYear = get(this.$store.state, 'academicYear', [])
        const id = get(this.$store.state, 'academic_year_id', '')
        let title = this.universityIntakes[0]
        academicYear.map(itm => {
          if (itm.id === id) {
            title = itm.title
            return
          }
        })
        return title
      },
      isDisabled () {
        const completionForm = get(this.$store.state, 'completionForm', {})
        const checkCompletion = {
          is_complete_file: completionForm.is_complete_file || false,
          is_financial_support_form: completionForm.is_financial_support_form || false,
          is_receipt_payment: completionForm.is_receipt_payment || false,
          is_recommendation_form: completionForm.is_recommendation_form || false,
          is_registration_form: completionForm.is_registration_form || false,
        }
        if (['mth', 'mmin', 'sert'].indexOf(this.programStudy) !== -1) {
          checkCompletion.is_motivation_form = completionForm.is_motivation_form || false
        }
        if (['sth', 'mdiv', 'mthu'].indexOf(this.programStudy) !== -1) {
          checkCompletion.is_faith_calling_form = completionForm.is_faith_calling_form || false
          checkCompletion.is_repentance_testimony_form = completionForm.is_repentance_testimony_form || false
          checkCompletion.is_health_form = completionForm.is_health_form || false
        }
        if (['mmin', 'sert'].indexOf(this.programStudy) !== -1) {
          checkCompletion.is_repentance_testimony_form = completionForm.is_repentance_testimony_form || false
        }
        const disabledData = []
        Object.keys(checkCompletion).map(itm => {
          if (!checkCompletion[itm]) {
            disabledData.push(itm)
          }
        })
        return disabledData.length > 0
      },
      statusRegistration () {
        return get(this.$store.state, 'status', 'init')
      },
      isFormComplete () {
        return this.statusRegistration === FORM_COMPLETE
      },
      isFormPending () {
        return this.statusRegistration === FORM_PENDING
      },
      isValidatePending () {
        return this.statusRegistration === VALIDATE_PENDING
      },
      isValidateComplete () {
        return this.statusRegistration === VALIDATE_COMPLETE
      },
      ifWaitAlert () {
        this.setInfo()
        return this.isFormComplete || this.isFormPending || this.isValidatePending || this.isValidateComplete
      },
      isFormAccepted () {
        this.setInfo()
        return this.statusRegistration === PASSED || this.statusRegistration === ACCEPTED || this.statusRegistration === STUDENT_AGREE
      },
      isRegistrationRejected () {
        return this.statusRegistration === REJECTED
      },
      rejectedReason () {
        return get(this.$store.state, 'rejected_reason', '')
      },
      isHideButton () {
        return this.statusRegistration !== INIT
      },
      getStudentAgreementData () {
        return get(this.$store.state, 'student_agreement_letter', {})
      },
      interviewDetail () {
        return get(this.$store.state, 'interview', '')
      },
    },
    async beforeRouteEnter (to, from, next) {
      const params = {
        entry_form_id: to.query.formId || null,
        program_study: to.query.prodi || null,
      }
      const resFormCompletion = await store.dispatch('getCompletionForm', params)
      const data = {}
      if (resFormCompletion.academic_year_id) {
        data.current_id = resFormCompletion.academic_year_id
      }
      const res = await store.dispatch('getAcademicYear', data)
      if (resFormCompletion.success && res.success) {
        next (vm => {
          vm.programStudy = get(resFormCompletion, 'program_study', '')
          vm.prodiTitle = text[`program.studi.${vm.programStudy}.title`]
          vm.period = get(resFormCompletion, 'academic_year', null) ? moment(new Date(get(resFormCompletion, 'academic_year', 0)*1000)).locale('id').format('YYYY') : `${new Date().getFullYear()}`
          vm.universityIntake = vm.getUniversityIntake
        })
      } else {
        next({
          name: REGISTRATION_STATUS
        })
      }
    },
    methods: {
      setInfo () {
        const status = this.statusRegistration
        if (status === ACCEPTED) {
          this.alertInfoMsg = 'Selamat anda telah diterima dan setuju untuk menjadi mahasiswa di STT Amanat Agung.'
        } else if (status === PASSED) {
          this.alertInfoMsg = 'Selamat anda telah diterima di STT Amanat Agung. Silakan kirim Surat Pernyataan Persetujuan Mahasiswa.'
        } else if (status === FORM_COMPLETE) {
          this.alertInfoMsg = 'Formulir pendaftaran sedang dalam pemeriksaan oleh Admin STT Amanat Agung.'
        } else if (status === FORM_PENDING) {
          this.alertInfoMsg = 'Menunggu kelengkapan formulir rekomendasi.'
        } else if (status === VALIDATE_COMPLETE || status === VALIDATE_PENDING) {
          this.alertInfoMsg = 'Proses pendaftaran sedang dalam pemeriksaan oleh Admin STT Amanat Agung.'
        } else if (status === STUDENT_AGREE) {
          this.alertInfoMsg = 'Surat pernyataan persetujuan mahasiswa sedang dalam pemeriksaan oleh Admin STT Amanat Agung.'
        }
      },
      handleSave () {
        this.handleOnSubmit('save_to_draft')
      },
      submitForm () {
        this.$refs.dialogConfirmSubmitForm && this.$refs.dialogConfirmSubmitForm.show()
      },
      handleBack () {
        this.$router.push({
          name: REGISTRATION_STATUS
        }).catch(() => {})
      },
      handleOnSubmit (type = 'submit') {
        loadscreen.show()
        const academicYearList = this.$store.state.academicYear
        const academicYear = this.$store.state.selectedAcademicYear
        let academicYearId = ''
        academicYearList.map(itm => {
          if (itm.title === academicYear) {
            academicYearId = itm.id
          }
        })
        const params = {
          entry_form_id: this.$route.query.formId || null,
          academic_year_id: academicYearId,
        }
        if (type !== 'submit') {
          params.is_save_to_draft = true
        }
        request(API_FORM_SUBMIT, params).then(res => {
          if (res.success) {
            type === 'submit' ? this.$refs.dialogInfoSuccess && this.$refs.dialogInfoSuccess.show() : this.handleBack()
          } else {
            this.alertMsg = get(res, 'resultInfo.resultMsg', '')
            this.isShowAlert = true
          }
        })
        loadscreen.hide()
      },
      submitStudentAgreement () {
        this.$refs.dialogConfirmSubmitStudentAgreement && this.$refs.dialogConfirmSubmitStudentAgreement.show()
      },
      handleSubmitStudentAgreement () {
        const params = {
          entry_form_id: this.$route.query.formId || null,
          student_agreement_letter_url: this.getStudentAgreementData.path
        }
        request(API_STUDENT_FORM_SUBMIT, params).then(res => {
          if (res.success) {
            this.$refs.dialogInfoSuccess && this.$refs.dialogInfoSuccess.show()
          } else {
            this.alertMsg = get(res, 'resultInfo.resultMsg', '')
            this.isShowAlert = true
          }
        })
        loadscreen.hide()
      }
    }
  }
</script>

<style lang="scss" scoped>
.registration-detail {
  font-size: 14px;
  .title-14 {
    font-weight: bold;
    font-size: 14px;
  }
}
</style>